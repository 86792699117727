@import url('https://fonts.googleapis.com/css2?family=Sedan:ital@0;1&display=swap');

.envelope {
    position: relative;
    width: 800px;
    height: 800px;
    margin: 50px auto;
    cursor: pointer;
    perspective: 1000px;
    background: url('https://i.ibb.co/wSD4hpx/istockphoto-1299046140-1024x1024-1.jpg');
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    transition: transform 0.6s ease;
}

.flap {
    position: absolute;
    width: 100%;
    height: 50%;
    background: url('https://i.ibb.co/wSD4hpx/istockphoto-1299046140-1024x1024-1.jpg');
    transform-origin: bottom;
    transition: transform 0.8s ease;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.body {
    position: absolute;
    top: 50%;
    width: 100%;
    height: 50%;
    background: url('https://i.ibb.co/wSD4hpx/istockphoto-1299046140-1024x1024-1.jpg');
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    z-index: -1;
    box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.1);
}

.letter {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 50%;
    background-color: #fafafa;
    overflow: hidden;
    transition: all 0.8s cubic-bezier(0.165, 0.84, 0.44, 1);
    transform-origin: bottom;
    z-index: -2;
    opacity: 0;
    font-family: "Sedan", serif;
    font-weight: 400;
    font-style: normal;
    font-size: 20px;
    padding: 20px;
    box-sizing: border-box;
    text-align: justify;
}

.open .flap {
    transform: rotateX(-180deg);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5);
}

.letter.fullSize {
    top: 0%;
    height: 100%;  /* Expand to full screen */
    opacity: 1;
    z-index: -2;
    transform: scale(1);  /* Scale up if necessary */
}

/* Happy Birthday text */
.happy-birthday {
    position: absolute;
    top: -3%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: "Sedan", serif;
    font-size: 36px;
    color: #ff0000;
    text-align: center;
    opacity: 0;
    transition: opacity 0.6s ease;
    z-index: 1000;
    white-space: nowrap;  /* Prevent text from wrapping */
}

.envelope:not(.open) .happy-birthday {
    opacity: 1;  /* Show when the envelope is closed */
}

/* Flying Photos Animation */
@keyframes fly {
    0% {
        transform: translate(-100vw, 100vh) rotate(0deg);
    }
    50% {
        transform: translate(50vw, -50vh) rotate(180deg);
    }
    100% {
        transform: translate(100vw, 100vh) rotate(360deg);
    }
}

/* Photo Styling */
.flying-photo {
    position: absolute;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    opacity: 0.7;
    animation: fly 10s infinite ease-in-out;
    z-index: 999;
    pointer-events: none;
}

/* Customize each photo's path */
.flying-photo-0 {
    animation-duration: 8s;
    animation-delay: 0s;
}

.flying-photo-1 {
    animation-duration: 12s;
    animation-delay: 2s;
}

.flying-photo-2 {
    animation-duration: 14s;
    animation-delay: 4s;
}

/* Mobile and Tablet Responsive Styles */
@media screen and (max-width: 768px) {
    .envelope {
        width: 100vw;
        height: 100vh;
        margin: 0;
    }

    .letter {
        font-size: 18px;
        padding: 15px;
    }

    .happy-birthday {
        font-size: 28px;
    }

    .flying-photo {
        width: 60px;
        height: 60px;
    }
}
